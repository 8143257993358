
import axios from 'axios'
import {  listePositionURL } from '../config/api'
import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';



export const  getListPosition = async (data) =>
{
    const token = "Bearer " + await encryptStorage.getItem('userInfo').userToken;
    const dataToSend = {matricule: data.data.matricule, date: data.data.date}
         //  console.log('[trajet service] = datatosend = ' , dataToSend)
            return axios.post(listePositionURL, dataToSend , {
                headers: {
                    'Authorization': token,
                    'Accept' : 'application/json',
                    'Content-Type': 'application/json'
                }
            }).then(res => res.data)
}


