export const LOAD_SIM_REQ = 'SIM/LOAD_SIM_REQ'
export const LOAD_SIM_FINISH = 'SIM/LOAD_SIM_FINISH'
export const LOAD_SIM_ERROR = 'SIM/LOAD_SIM_ERROR'

export const LOAD_SIMS = 'SIM/LOAD_SIM'
export const SIMS_LOADED = 'SIM/SIM_LOADED'

export const DELETE_SIMS = 'SIM/DEL_SIM'

export const ADD_SIMS = 'SIM/ADD_SIM'

export const UPDATE_SIMS = 'SIM/UPDATE_SIM'




export const LOAD_CLIENT_REQ = 'CLIENT/LOAD_CLIENT_REQ'
export const LOAD_CLIENT_FINISH = 'CLIENT/LOAD_CLIENT_FINISH'
export const LOAD_CLIENT_ERROR = 'CLIENT/LOAD_CLIENT_ERROR'

export const LOAD_CLIENTS = 'CLIENT/LOAD_CLIENT'
export const CLIENTS_LOADED = 'CLIENT/CLIENT_LOADED'

export const DELETE_CLIENTS = 'CLIENT/DEL_CLIENT'

export const ADD_CLIENTS = 'CLIENT/ADD_CLIENT'

export const UPDATE_CLIENTS = 'CLIENT/UPDATE_CLIENT'








export const LOAD_VEHICULE_REQ = 'VEHICULE/LOAD_VEHICULE_REQ'
export const LOAD_VEHICULE_FINISH = 'VEHICULE/LOAD_VEHICULE_FINISH'
export const LOAD_VEHICULE_ERROR = 'VEHICULE/LOAD_VEHICULE_ERROR'
export const LOAD_VEHICULES = 'VEHICULE/LOAD_VEHICULE'
export const LOAD_VEHICULES_FOR_CUSTOMER = 'VEHICULE/LOAD_VEHICULES_FOR_CUSTOMER'
export const LOAD_VEHICULE_SELECTED = 'VEHICULE/LOAD_VEHICULE_SELECTED'
export const VEHICULES_LOADED = 'VEHICULE/VEHICULE_LOADED'
export const DELETE_VEHICULES = 'VEHICULE/DEL_VEHICULE'
export const ADD_VEHICULES = 'VEHICULE/ADD_VEHICULE'
export const UPDATE_VEHICULES = 'VEHICULE/UPDATE_VEHICULE'










export const LOAD_DEVICE_REQ = 'DEVICE/LOAD_DEVICE_REQ'
export const LOAD_DEVICE_FINISH = 'DEVICE/LOAD_DEVICE_FINISH'
export const LOAD_DEVICE_ERROR = 'DEVICE/LOAD_DEVICE_ERROR'

export const LOAD_DEVICES = 'DEVICE/LOAD_DEVICE'
export const DEVICES_LOADED = 'DEVICE/DEVICE_LOADED'

export const DELETE_DEVICES = 'DEVICE/DEL_DEVICE'

export const ADD_DEVICES = 'DEVICE/ADD_DEVICE'

export const UPDATE_DEVICES = 'DEVICE/UPDATE_DEVICE'







export const LOAD_USER_REQ = 'USER/LOAD_USER_REQ'
export const LOAD_USER_FINISH = 'USER/LOAD_USER_FINISH'
export const LOAD_USER_ERROR = 'USER/LOAD_USER_ERROR'

export const LOAD_USERS = 'USER/LOAD_USER'
export const USERS_LOADED = 'USER/USER_LOADED'

export const DELETE_USERS = 'USER/DEL_USER'

export const ADD_USERS = 'USER/ADD_USER'

export const UPDATE_USERS = 'USER/UPDATE_USER'







export const LOAD_PAIEMENT_REQ = 'PAIEMENT/LOAD_PAIEMENT_REQ'
export const LOAD_PAIEMENT_FINISH = 'PAIEMENT/LOAD_PAIEMENT_FINISH'
export const LOAD_PAIEMENT_ERROR = 'PAIEMENT/LOAD_PAIEMENT_ERROR'

export const LOAD_PAIEMENTS = 'PAIEMENT/LOAD_PAIEMENT'
export const PAIEMENTS_LOADED = 'PAIEMENT/PAIEMENT_LOADED'

export const DELETE_PAIEMENTS = 'PAIEMENT/DEL_PAIEMENT'

export const ADD_PAIEMENTS = 'PAIEMENT/ADD_PAIEMENT'

export const UPDATE_PAIEMENTS = 'PAIEMENT/UPDATE_PAIEMENT'

export const LOAD_PAIEMENTS_CLIENT = 'PAIEMENT/LOAD_PAIEMENTS_CLIENT'








//marker

export const LOAD_MARKER_POSITION = "LOAD_MARKER_POSITION"
export const MARKERS_LOADED_POSITION = "MARKERS_LOADED_POSITION"
export const MARKERS_ERROR = "MARKERS_ERROR"




// Command type

export const LOAD_TYPE_COMMAND = "LOAD_TYPE_COMMAND"
export const TYPE_COMMAND_LOADED = "TYPE_COMMAND_LOADED"


// trajet

export const LOAD_TRAJET = "LOAD_TRAJET"
export const TRAJET_LOADED = "TRAJET_LOADED"


// map action
// envoi command a la map

export const CENTRALISE_TO_MARKER = "CENTRALISE_TO_MARKER"
export const MARKER_CENTRALISED = "MARKER_CENTRALISED"    // end of action CENTRALISE_TO_MARKER



// marger Date Dyn
export const LOAD_DATE_DYN_TRAJET = "LOAD_DATE_DYN_TRAJET"
export const DATE_DYN_TRAJET_LOADED = "DATE_DYN_TRAJET_LOADED"



export const LOAD_LECTEUR_DYN_TRAJET = "LOAD_LECTEUR_DYN_TRAJET"
export const PLAY_LECTEUR_DYN_TRAJET = "PLAY_LECTEUR_DYN_TRAJET"
export const PAUSE_LECTEUR_DYN_TRAJET = "PAUSE_LECTEUR_DYN_TRAJET"
export const PREP_RELOAD_LECTEUR_DYN_TRAJET = "PREP_RELOAD_LECTEUR_DYN_TRAJET"
export const RELOAD_LECTEUR_DYN_TRAJET = "RELOAD_LECTEUR_DYN_TRAJET"
export const INC_SPEED_LECTEUR_DYN_TRAJET = "INC_SPEED_LECTEUR_DYN_TRAJET"
export const STOP_LECTEUR_DYN_TRAJET = "STOP_LECTEUR_DYN_TRAJET"




export const MODE_MAP = "MODE_MAP"
export const MODE_TRAJET = "MODE_TRAJET"
export const MODE_COMMAND = "MODE_COMMAND"
export const MODE_PARAMATRE = "MODE_PARAMATRE"


//param


export const LOAD_PARAM_USER_REQ = 'PARAM_USER/LOAD_PARAM_USER_REQ'
export const LOAD_PARAM_USER_REQ_FINISH = 'PARAM_USER/LOAD_PARAM_USER_REQ_FINISH'
export const LOAD_PARAM_USER_REQ_ERROR = 'PARAM_USER/LOAD_PARAM_USER_REQ_ERROR'

export const LOAD_PARAM_USER = 'PARAM_USER/LOAD_PARAM_USER'
export const LOAD_PARAM_FOR_CURRENT_USER = 'PARAM_USER/LOAD_PARAM_FOR_CURRENT_USER'
export const PARAM_USER_LOADED = 'PARAM_USER/PARAM_USER_LOADED'


export const UPDATE_PARAM_USER = 'PARAM_USER/UPDATE_PARAM_USER'
export const UPDATE_PARAM_CURRENT_USER = 'PARAM_USER/UPDATE_PARAM_CURRENT_USER'