import { connect } from "react-redux";
import { lineString, along, lineDistance } from '@turf/turf';
import { Marker } from '@react-google-maps/api';
import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { memo } from "react";


const OPTIONS = { units: 'feet' };
// 30.1 seconds, the .1 is to allow a buffer for the next set of cords to load
// I know it's not exact, but it's close :)
const STEPS = 30100 + 50000;



const AnimatedMarkerTrajet = (props) => {

    const [currentLocation, setCurrentLocation] = useState([0,0]);
    const [tabPosTrajet, setTabPosTrajet] = useState([]);
    const [urlImage, setUrlImage] = useState("")
    let frameId;


    useEffect(()=> 
    {

        if (props.loadTrajetFromSaga.positions != undefined)
        {
            
            const convTab = props.loadTrajetFromSaga.positions.map((elem) => {
                return ([elem.latitude, elem.longitude])
            })
            if (convTab.length > 0)
            {                
                setTabPosTrajet(convTab);
            }
            else
            {
                setTabPosTrajet([]);
            }
        }
    }, [props.loadTrajetFromSaga.positions])


    ////////
   

    //////


    useEffect(() => 
    {
        
        

        let arc = [];
        let startTime = 0;

        //console.log("tabProjet = ", tabPosTrajet.length) 
        if (!tabPosTrajet.length) {// exit if no cords in array
          return;
        }
        
       // console.log("tabProjet = ", tabPosTrajet.length) 

        const line = lineString(tabPosTrajet); // our array of lat/lngs
        const distance = lineDistance(line, OPTIONS);
        for (let i = 0; i < distance; i += distance / STEPS) {
          let segment = along(line, i, OPTIONS);
          arc.push(segment.geometry.coordinates);
        }
    
        function animate(timestamp) 
        {// animate function to set location

            if (props.loadTrajetLecteurInfo.playPauseReload === 1)
            return
            
          const runtime = timestamp - startTime;
          const timeStep = Math.round(runtime);
         // console.log("props.loadTrajetLecteurInfo.playPauseReload", props.loadTrajetLecteurInfo.playPauseReload)
          setCurrentLocation(arc[timeStep] || arc[arc.length - 1]);
          if (timeStep <= STEPS) {
            frameId = window.requestAnimationFrame(animate);
          }
        }
        window.cancelAnimationFrame(frameId);
        frameId = window.requestAnimationFrame(timeStamp => {
          startTime = timeStamp;
          animate(timeStamp);
        });
      },
      [tabPosTrajet]
    );

   //console.log("outside playPauseReload", props.loadTrajetLecteurInfo.playPauseReload)

    useEffect(() => {
       // console.log("inside playPauseReload", props.loadTrajetLecteurInfo.playPauseReload)
    }, [currentLocation])
    
    let urlPhotoVehicule = "";
    if ((props.loadVehiculSelectedRedux.photoVehicule === "") || (props.loadVehiculSelectedRedux.photoVehicule === null))
    {
        urlPhotoVehicule = (props.loadVehiculSelectedRedux.typeVehicule === 'Voiture') ? 'https://www.sentracking.com/photmat/auto.png' : 'https://www.sentracking.com/photmat/moto.png'
    }
    else
    {
        urlPhotoVehicule = 'https://www.sentracking.com/photmat/' + props.loadVehiculSelectedRedux.photoVehicule + ".png"
    }

   //console.log("url photo = ", urlPhotoVehicule)
    return (
        <Marker 
        //anchor="center"
            position={{lat: currentLocation[0], lng: currentLocation[1] }}
            url={urlPhotoVehicule}
            icon={{
    
                url: urlPhotoVehicule,
                
                scaledSize: new window.google.maps.Size(37, 37)
        
            }}
        />
           
        
    )
}

const mapStateToProps = (state) => 
{
   
    return (
        {
            loadTrajetFromSaga: state.trajet,
            loadVehiculSelectedRedux: state.vehicules.vehiculeSelected,
            loadTrajetLecteurInfo: state.lecteurTrajet,
        }
    )
}

export default connect(mapStateToProps)(memo(AnimatedMarkerTrajet));