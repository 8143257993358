

import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { InfoWindow, Marker } from '@react-google-maps/api';
import ReactDOMServer from 'react-dom/server'
import reactElementToJSXString from 'react-element-to-jsx-string';

const TrajetArret = (props) => {


  const [activeMarker, setActiveMarker] = useState(null);
  const [positionTrajet, setPositionTrajet] = useState([]);
  const [tabPosArret, setTabPosArret] = useState([]);
  const [markerLoop, setMarkerLoop] = useState(<></>);




useEffect(() => 
{
  if (props.loadTrajetFromSaga.positions)
  {
    if (props.loadTrajetFromSaga.positions.length > 0)
    {
      
      setPositionTrajet(props.loadTrajetFromSaga.positions)
      //props.playLecteurDispatch();
      //console.log(props.loadTrajetFromSaga.positions)
      
    }
    else
    {
      setPositionTrajet([])
      //setLoading(false)
    }
  }
}, [props.loadTrajetFromSaga.positions])


  const handleActiveMarker = (marker) => 
  {
    
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    
  }

 

useEffect(() => {

  if (positionTrajet !== undefined)
  {
    const positionArret = positionTrajet.filter((arret, i) =>  ((i != 0) && (arret.enMarche === false) && (positionTrajet[i - 1].enMarche === true)))
    setTabPosArret(positionArret)
  }

}, [positionTrajet])



useEffect(() => {
  
  setActiveMarker(null)
}, [props.mapClicked])



const RenderMarker = () => {
  return (
    <>
            {tabPosArret.map((cars) => {

              const contentInfoBox = <div> 
              <p> </p> <p> 
              <b>Date :</b> { cars.dateReceptionPosition } 
              </p><p><b>Etat vehicule : </b>  { cars.enMarche === true ? 'Allumer' : 'Eteint' } 
              </p><p><b> Adresse :</b> { cars.adresse } </p> 
              </div>;

              return (
                
                <Marker
                   
                    key={"position_" + cars.identifiantPosition}
                    onClick={() => {handleActiveMarker("position_" + cars.identifiantPosition)}}
                    position={{lat: Number(cars.latitude), lng: Number(cars.longitude) }}
                    
                    
                  >
                     {activeMarker === "position_" + cars.identifiantPosition ? (
                     <InfoWindow  
                     position={{lat: Number(cars.latitude), lng: Number(cars.longitude) }}
                     onCloseClick={() => setActiveMarker(null)} >
                       
                        {contentInfoBox}
                    </InfoWindow>
                    ) : null}
                      
          
              
                  </Marker >
                  
              )
            })
          }

    </>
)
}

/*
    const StopMarkerMemo = useMemo(() => 
    {

      
        
      const stopMarkers = () => 
      {
        let cnt = 0;
        
        let positionArret;
        
        if (props.positionTrajet !== undefined)
        {
        positionArret = props.positionTrajet.filter((arret, i) =>  ((i != 0) && (arret.enMarche === false) && (props.positionTrajet[i - 1].enMarche === true)))
      }
      else {
        positionArret = []
      }
        //console.log("SIZE TAB = ", positionArret.length)

       // console.log("position arret = ", positionArret)


        
         const positionLoop =  positionArret.map((cars) => 
         {

          const contentInfoBox = <div> 
          <p> </p> <p> 
            <b>Date :</b> { cars.dateReceptionPosition } 
            </p><p><b>Etat vehicule : </b>  { cars.enMarche === true ? 'Allumer' : 'Eteint' } 
            </p><p><b> Adresse :</b> { cars.adresse } </p> 
            </div>


         // console.log("cars " , cnt , " = ",  contentInfoBox)
         
              return (
                      <Marker
                      
                        key={"position_" + cars.identifiantPosition}
                        onClick={() => handleActiveMarker("position_" + cars.identifiantPosition)}
                        position={{lat: Number(cars.latitude), lng: Number(cars.longitude) }}
                      >
                         {activeMarker === "position_" + cars.identifiantPosition ? (
                         <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                            {contentInfoBox}
                        </InfoWindow>
                        ) : null}
                          
                       
                  
                      </Marker >
                    )
             }
           
         
         )
         
         return (positionLoop);
         
         
       }
    
       return (stopMarkers)
    }, [props.positionTrajet])

    */
    
    return (
        <>
               
          <RenderMarker />

         
        </>
    )
}


const mapStateToProps = (state) => {

  //console.log("state.vehicules.vehiculeSelected (google map)", state.vehicules.vehiculeSelected)
  
    return ({
      loadTrajetFromSaga: state.trajet,
       
    }
    )
  
  }
  

export default connect(mapStateToProps, null)(React.memo(TrajetArret));

//export default TrajetArret;