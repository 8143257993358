import {combineReducers} from 'redux'
import { clientReducer } from './clientReducer'
import { dateDynReducer } from './dateDynReducer'
import { deviceReducer } from './deviceReducer'
import { lecteurTrajetReducer } from './lecteurTrajetReducer'
import { mapActionReducer } from './mapActionReducer'
import { mapReducer } from './mapReducer'
import { modeCommandReducer } from './modeCommandReducer'
import { paiementReducer } from './paiementReducer'
import { paramUserReducer } from './paramUserReducer'
import { simCardReducer } from './simCardReducer'
import { trajetReducer } from './trajetReducer'
import { typeCommandReducer } from './typeCommandReducer'
import { userReducer } from './userReducer'
import { vehiculeReducer } from './vehiculeReducer'


export default combineReducers({
    simCards: simCardReducer,
    clients: clientReducer,
    devices: deviceReducer,
    vehicules: vehiculeReducer,
    users: userReducer,
    paiements: paiementReducer,
    mapMarkers: mapReducer,
    typeCommand: typeCommandReducer,
    trajet: trajetReducer,
    mapAction: mapActionReducer,
    lecteurTrajet: lecteurTrajetReducer,
    dateDyn: dateDynReducer,
    modeCommand: modeCommandReducer,
    paramUser: paramUserReducer,
})

