
import React from "react";

import { createBrowserHistory } from "history";
import { BrowserRouter,   Redirect, useHistory, Switch, Route } from "react-router-dom";
import {      CompatRoute, CompatRouter, Navigate, Router } from "react-router-dom-v5-compat";


import AuthLayout from "layouts/Auth.js";
//import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import UserLayout from "layouts/User.js"
import PayementValidator from "layouts/PayementValidator.js"

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import { AuthContext } from 'context/authentificationContext';
import { Provider } from "react-redux";
import store from "store";

import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';
import { useEffect } from "react";
import { verifPurchaseService } from './services/paiementService';



//require('dotenv').config({ path: '/src/confvariable.env' })


const hist = createBrowserHistory();


const App = () => 
{




/*
  const verifPurchase = async () => 
  {

    try {
      if (loginState.userInfo !== null)
      {
        
        const email = JSON.parse(loginState.userInfo).userName;
        const token = JSON.parse(loginState.userInfo).userToken;

        console.log("email ", email)
        console.log("token ", token)
       
/*
        if (email !== undefined)
        {
          if (email.length > 0)
          { 
            verifPurchaseService(email, token).then(
              (res) => {
                EncryptedStorage.setItem('purchaseExp', String(res.data.dateExpirationMs))

              
                let expireDateOver = Date.now() < res.data.dateExpirationMs;
       
                if (expireDateOver === true)
                {
                  setIsSuscribeOk(true); 
                }
                else
                {
                  setIsSuscribeOk(false); 
                
                }
              }
            ).catch(
              (error) => {
               // verifPurchaseLocal();
                console.log("error verif purchase service in app.js", error)
              }
            )

            
          
          }
        }
        */
       /*
      }
    } catch (error) 
    {
      //verifPurchaseLocal();
      
    }

    
 }
*/
  
  const initialLoginState = {
    isLoading: true,
    //userName: null,
    //userToken: null,
    userInfo: null

  };

  const loginReducer = (prevState, action) => {
    switch( action.type ) {
      /*case 'RETRIEVE_TOKEN': 
      //// console.log("retrieve token", action.token)
      
        return {
          ...prevState,
          userToken: action.token,
          isLoading: false,
        };*/
        case 'RETRIEVE_USERINFO': 
       //console.log("RETRIEVE_USERINFO", action.userInfo)
      
        return {
          ...prevState,
          userInfo: action.userInfo,
          isLoading: false,
        };
      case 'LOGIN': 
     // setUserTokenState(action.id)
     
        return {
          ...prevState,
          
          //userName: action.id,
          //userToken: action.token,
          userInfo: action.userInfo,
          isLoading: false,
        };
      case 'LOGOUT': 
        return {
          ...prevState,
          //userName: null,
          //userToken: null,
          userInfo: null,
          isLoading: false,
        };
    }
  };

  const getIsEnRegleAPI = async (userName, userToken) => {
    try {
      const info = (await verifPurchaseService(userName, userToken)).data;
      return  (info);
    } 
    catch (error) 
    {
      console.error("Erreur getIsEnRegleAPI [App.js] = ", error);
      return (null);
    }
     
  }



  const [loginState, dispatch] = React.useReducer(loginReducer, initialLoginState);

    const authContext = React.useMemo(() => (
        {
        signIn: async(foundUser) => {
          
          //console.log("foundUser = ", foundUser)
          // setUserToken('fgkj');
          // setIsLoading(false);
          const userToken = String(foundUser.token);
          const userName = foundUser.username;
          const userRoleTab = foundUser.roleDtos;
          let userIsEnRegle  = null;
          let userEnRegleEnMs = null;

          const verifEnRegle = await getIsEnRegleAPI(userName, userToken);
          //console.log(" verifEnRegle =  ", verifEnRegle)

          if (verifEnRegle !== null)
          {
             userIsEnRegle = verifEnRegle.isEnRegle;
             userEnRegleEnMs = verifEnRegle.dateExpirationMs;

          }
          else
          {
            //console.log(" verifEnRegle = NULL ")
          }
          let role = "";

          if (userRoleTab.length > 0)
          {
            role = userRoleTab[0].role;
          }

          let userInfo = {userToken: userToken, userName: userName, role: role, isEnRegle: userIsEnRegle, isEnRegleMs: userEnRegleEnMs}
          const userInfoStringify = JSON.stringify({userToken: userToken, userName: userName, role: role})

          
          //setUserName(userName)
          
          try {
           
            await encryptStorage.setItem('userInfo', userInfoStringify);
          } catch(e) {
            // console.log(e);
          }
          // console.log('user info: ', userInfo);
          //dispatch({ type: 'LOGIN', id: userName, token: userToken });
          dispatch({ type: 'LOGIN', userInfo: userInfo });
        },
        signOut: async() => {
          // setUserToken(null);
          // setIsLoading(false);
          try {
           
           //await EncryptedStorage.removeItem('userInfo');
           await encryptStorage.removeItem('userInfo');
           //hist.push('/auth')
           
           
            
          } catch(e) {
            // console.log(e);
          }
          dispatch({ type: 'LOGOUT' });
        },
        signInFromLocal: async() => {
         
          let userInfo;
          userInfo = null;
          
          try {
            //userToken = await AsyncStorage.getItem('userToken');
            //userInfo = await AsyncStorage.getItem('userInfo');
            userInfo = await encryptStorage.getItem('userInfo');

             
            if (userInfo === undefined)
            {
              userInfo = null;
              dispatch({ type: 'LOGOUT' });
            }
            else 
            {
             // const verifEnRegle =  getIsEnRegleAPI(userInfo.userName, userInfo.userToken);

             
              verifPurchaseService(userInfo.userName, userInfo.userToken).then((res) => {
                
                const userInfoCpy = {
                  ...userInfo,
                  isEnRegle: res.data.isEnRegle, 
                  isEnRegleMs: res.data.dateExpirationMs
                }
                dispatch({ type: 'RETRIEVE_USERINFO', userInfo: userInfoCpy });
              })
              
              
            }
            //userInfo = await AsyncStorage.getItem('userInfo');
            //// console.log(userInfo)
           
            //console.log("userInfo of useEffect = ", userInfo)
            
          } catch(e) {
            // console.log('PROBLEME RECUPERATION DONNEE', e);
          }
        },
        
        
      }), [])

      useEffect(() => { 
        setTimeout(async() => {
          // setIsLoading(false);
          
          let userInfo;
          userInfo = null;
          
          try {
            //userToken = await AsyncStorage.getItem('userToken');
            //userInfo = await AsyncStorage.getItem('userInfo');
            userInfo = await encryptStorage.getItem('userInfo');

             
            if (userInfo === undefined)
            {
              userInfo = null;
              dispatch({ type: 'LOGOUT' });
            }
            else 
            {
              
              verifPurchaseService(userInfo.userName, userInfo.userToken).then((res) => {
                
                const userInfoCpy = {
                  ...userInfo,
                  isEnRegle: res.data.isEnRegle, 
                  isEnRegleMs: res.data.dateExpirationMs
                }
                dispatch({ type: 'RETRIEVE_USERINFO', userInfo: userInfoCpy });
              })
              
              
            }
            //userInfo = await AsyncStorage.getItem('userInfo');
            //// console.log(userInfo)
           
            //console.log("userInfo of useEffect = ", userInfo)
            
          } catch(e) {
            // console.log('PROBLEME RECUPERATION DONNEE', e);
          }
           //console.log('user token: ', userInfo);
          //dispatch({ type: 'RETRIEVE_TOKEN', token: userToken });
         // dispatch({ type: 'RETRIEVE_USERINFO', userInfo: userInfo });
        }, 200);
      }, []);


      useEffect(() => {
        if (loginState.userInfo === null)
        {
          hist.push('/auth/login-page')
        }
      }, [loginState])

      useEffect(() => {
        
       // console.log("loginState = ", loginState)

      }, [loginState])

     
    return(
      <BrowserRouter history={hist}>
        <CompatRouter>
        <Provider store={store}>
            <AuthContext.Provider value={authContext}>
                
              { loginState.userInfo !== null ? 
                (
                  loginState.userInfo.role === "ROLE_ADMIN" ?
                  (
                  <Switch>     
                    <Route path="/admin" >
                      <AdminLayout />
                    </Route>
                    
                    <Route path="/**" render={() => <Navigate to="/admin/dashboard" />} />
                   {/* <Redirect from="/" to="/admin/dashboard" /> */}
                    
                  </Switch>
                  )
                  :
                  (
                    loginState.userInfo.isEnRegle === true ? (
                      <Switch>     
                        <Route path="/user" >
                          <UserLayout /> 
                        </Route>
                       { /* <Redirect from="/" to="/user/dashboard" />  */ }
                       <Route path="/**" render={() => <Navigate to="/user/dashboard" />} />
                      </Switch>
                    )
                    :
                    (
                      <Switch>     
                        <Route path="/validator">
                          <PayementValidator /> 
                        </Route>
                        
                        {/* <Redirect from="/" to="/validator/payement" /> */}
                        <Route path="/**" render={() => <Navigate to="/validator/payement" />} />
                      </Switch>   
                    )
                  
                  )
                ) : 
                
                (  
                  <Switch>
                        <Route path="/auth"> 
                          <AuthLayout />
                        </Route>
                        <Route path="/**" render={() => <Navigate to="/auth/login-page" />} />
                    </Switch> 
                )
                
              }
                
            </AuthContext.Provider>
    </Provider>
    </CompatRouter>
    </BrowserRouter>
    )
}

export default App;