
import { InfoWindow, Marker } from '@react-google-maps/api';

import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Table from "components/Table/Table.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";


import React, { useEffect, useMemo, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import { connect } from 'react-redux';
import moment from 'moment';


const TabTrajetArret = (props) => {

  const [positionArretHook, setPositionArretHook] = useState([])



  useEffect(() => {
    let positionArret;
        
        if (props.loadTrajetFromSaga !== undefined)
        {
        positionArret = props.loadTrajetFromSaga.filter((arret, i) =>  ((i != 0) && (arret.enMarche === false) && (props.loadTrajetFromSaga[i - 1].enMarche === true)))
        }
      else {
        positionArret = []
        }
        //console.log("SIZE TAB = ", positionArret.length)
         let cnt = 0;
         const positionLoop =  positionArret.map((cars, cnt) => 
         {
           cnt++;
          //console.log("cars =  " , cnt , " = ",  cars)
          const date = moment(cars.dateReceptionPosition).format('DD-MM-YYYY HH:mm:ss');
          let duree = 0;
          
          if (cnt > 1)
          {
            
            var date1 = moment(positionArret[cnt - 1].dateReceptionPosition);
           
            var date2 = moment(positionArret[cnt - 2].dateReceptionPosition);
            
            duree = date1.diff(date2, 'minutes');
           
          }
              return (
                      [cnt, props.loadVehiculSelectedRedux.numeroImmatriculation, date, cars.adresse, duree + " mn"]
                    )
             }
           
         
         )
         setPositionArretHook(positionLoop);
  }, [props.loadTrajetFromSaga])


    /*
azimut: ""
dateReceptionPosition: "2021-07-01 10:08:49"
emetPosition: false
enMarche: false
identifiantPosition: 956859
latitude: 14.669018333
longitude: -17.43527333
modeleDevice: "HQ"
numeroDevice: "7801083680"
photoVehicule: "051a29ff407887446b86f3dbd6a58debc3d77c23"
typeMouvement: "ARRET"
typeVehicule: "Voiture"
vitesse: "000082"

    */
     
    
    return (
        <>
        <GridContainer
    
    direction="column"
    
    spacing={2}>
      <GridItem xs={12}>
      
        <Card>

        
          <CardBody>
          
          <Table 
          maxHeight={300}
            hover={false}
              handleClick = {() => {}}
              indexCol = {5}
              tableHeaderColor="primary"
              tableHead={["#", "vehicule", "Date", "Adresse ", "temps"]}
              tableData={positionArretHook}
              
            />
            
          </CardBody>
        </Card>
        
      </GridItem>
    </GridContainer>
         
        </>
    )
}


const mapStateToProps = (state) => {

  //console.log("state.vehicules.vehiculeSelected (google map)", state.vehicules.vehiculeSelected)
  
    return ({
      loadTrajetFromSaga: state.trajet.positions,
      loadVehiculSelectedRedux: state.vehicules.vehiculeSelected,
    }
    )
  
  }
  

export default connect(mapStateToProps, null)(TabTrajetArret);