import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
//import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useState } from "react";
import { useEffect } from "react";
import { getAuthentificationData } from 'services/authentificationService';

import { useContext } from "react";
import { AuthContext } from "context/authentificationContext";

//import { encryptStorage } from 'components/EncryptedStorage/encryptedStorage';


const useStyles = makeStyles(styles);





export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden");
  const [email, setEmail] = useState("")
  const [emailState, setEmailState] = useState("")

  const [isLoginError, setIsLoginError] = useState(false);
  const [password, setPassword] = useState("")
  const [passwordState, setPasswordState] = useState("")
  
  const { signIn } = useContext(AuthContext);

  
  //const {REACT_APP_ENC_STO_K} = process.env
  const loginHandle =  (userName, password) => 
    {

        
        if ( email.length === 0 || password.length === 0 ) {
            /*
            Alert.alert('Saisie Incorecte!', 'L email et le mot de passe doivent avoir une valeur.', [
                {text: 'Ok'}
            ]);
            */
            return;
        }
     
        
              getAuthentificationData(userName, password).then(
                (res) => {
                  let role = ""
                    //console.log('getAuthentificationData res =  ', res.data)
                  setIsLoginError(false)
                 signIn(res.data);
                }
             ).catch((err) => 
             {
              setIsLoginError(true);
                /*Alert.alert('Mauvais utilisateur!', 'Le nom  or le password est incorecte.', [
                    {text: 'Okay'}
                ]);*/
               // console.log('Erreur getAuthentificationData verifier USER et password', err)
             })
                    
    }

    const handleSubmit = () => {
        
      loginHandle(email, password);

       //console.log(' KEY = ',process.env)
        //console.log("handleSubmit = ", data)
      }

  const verifyLength = (value, length) => {
    if (value.length > length) {
      return true;
    }
    return false;
  };


  useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });


  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
                
                    
                <div style={{height: 60}}>
                  
                </div>
                    
                
               
              </CardHeader>
            
              <CardBody>
              {isLoginError === true ? (
              <CardFooter className={classes.justifyContentCenter}>
                  <p style={{color: 'red'}}> Login ou mot de passe incorrecte </p>
              </CardFooter>
              )
              :
              (<></>)
              }
                <CustomInput
                  labelText="Email..."
                  id="email"
                  
                  formControlProps={{
                    fullWidth: true,
                   
                  }}
                  inputProps={{
                    onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setEmailState("success");
                        } else {
                          setEmailState("error");
                        }
                        setEmail(event.target.value);
                      },

                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => {
                        if (verifyLength(event.target.value, 0)) {
                          setPassword("success");
                        } else {
                          setPasswordState("error");
                        }
                        setPassword(event.target.value);
                      },
                      
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
              </CardBody>
             
              <CardFooter className={classes.justifyContentCenter}>
               
                <Button 
                color="info" 
                simple 
                size="lg" 
                block
                onClick={handleSubmit}
                >
                 Valider
                </Button>
              </CardFooter>
             
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
