import * as actionType from '../config/actionType'

const initialState = [];

export const trajetReducer = (state = initialState, action) => {
    
    switch (action.type)
    {
        case actionType.TRAJET_LOADED:
            
            return(
                action.position
            )

        default:
            return (state);
    }
}