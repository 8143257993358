import React, { useCallback, useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
import Assignment from "@material-ui/icons/Assignment";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";


import * as notif from "components/Notification/notification"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";

import stylesAlert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";



import {  useHistory, useRouteMatch } from "react-router-dom";
import { loadSimCardAction, deleteSimCardAction} from "action/simCardAction";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { useNavigate } from "react-router-dom-v5-compat";


//import product1 from "assets/img/product1.jpg";

const useStyles = makeStyles(styles);
const useStyleAlert = makeStyles(stylesAlert)

function CarteSim(props) 
{
  //const initialTableData = [{"id" : "2", "nom": "John Doe", "prenom": "Design", "anne": "2012", "prix":"€ 89,241"}]
  //const initialTableData = [["2", "John Doe", "Design", "2012", "€ 89,241", roundButtons]]
  const actualURL = useRouteMatch();
  const [tableData, setTableData] = useState([]);
  const history = useNavigate();
  const {loadSimCardFromSaga, simCardLoadDispatch, simCardDeleteDispatch} = props;
  const [alert, setAlert] = React.useState(null);
  const [deleteStatut, setDeleteStatut] = React.useState(false);


  const dispatchRefreshTable = useCallback(() => {
    simCardLoadDispatch()
  }, [simCardLoadDispatch])


  const loadCardFromSagaUnique = useCallback(() => {
    return (loadSimCardFromSaga)
  }, [loadSimCardFromSaga])


  useEffect(() => {
    dispatchRefreshTable()
  },[dispatchRefreshTable])


  useEffect(() => 
  {
    if (deleteStatut === true)
    {
      if (loadSimCardFromSaga.loading === false)
        {
          let msg;
            let title;
          if (loadSimCardFromSaga.error.length > 0)
          {
  
            title = "Echeque Supression Sim"
              msg = "La Sim n a pas ete suprime";
            notif.showFailedNotification(title, msg)
            
          }
          else
          {
            title = "Supression Sim"
              msg = "La Sim   a ete suprimer";
            notif.showSuccessNotification(title, msg)
            setAlert(
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Suprimer!"
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
              >
                Cette article a ete supprimer.
              </SweetAlert>
            );
          }
          setDeleteStatut(false);
        }
    }

  }, [deleteStatut, loadSimCardFromSaga])
  

  const handleRefresh = () => {
    simCardLoadDispatch()
  }


  const successDelete = (id) => {
    
    const data = 
    {
      'numeroDeSerieSim' : id
    }

    simCardDeleteDispatch(data);
    setDeleteStatut(true)
    /*
    
    */
  };
  const cancelDetele = () => {
    setAlert(
      <SweetAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Annuler"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      >
        Votre article n a pas ete supprimer
      </SweetAlert>
    );
  };
  const hideAlert = () => {
    setAlert(null);
  };




/*
active: true
dateReceptionSim: "2020-11-11T00:00:00.000+00:00"
etatSim: "new"
identifiantSim: 8
numeroCarteSim: "778188858"
numeroDeCommandeSim: "command"
numeroDeSerieSim: "444433"
*/

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <SweetAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Etes vous sure ?"
      onConfirm={() => successDelete(id)}
      onCancel={() => cancelDetele()}
      confirmBtnCssClass={classesAlert.button + " " + classesAlert.success}
      cancelBtnCssClass={classesAlert.button + " " + classesAlert.danger}
      confirmBtnText="Suprimer!"
      cancelBtnText="Annuler"
      showCancel
    >
      Vous ne pourez pas recuperer cette article!
    </SweetAlert>
  );
};


const classes = useStyles();
const classesAlert = useStyleAlert();

const handleButDelete = (id) => 
{
  warningWithConfirmAndCancelMessage(id);
 
}

const handleButUpdate = (id) => {


  history(`${actualURL.path}/${id}/edit`)

}


 

const roundButtons = (id) => 
{
  const tabRound = [
  { color: "success", icon: Edit , onClick: handleButUpdate},
  { color: "danger", icon: Close, onClick: handleButDelete }
].map((prop, key) => {
  return (
    <Button
      round
      color={prop.color}
      className={classes.actionButton + " " + classes.actionButtonRound}
      key={key}
      onClick={() => {prop.onClick(id)}}

    >
      <prop.icon className={classes.icon} />
    </Button>
  );
});
return tabRound
}

useEffect(() => 
{
  const activeStr = (active) => 
  {
    return (active ? "active" : "desactive")
  }


 const tabLoad = loadCardFromSagaUnique().simCards;
 let table = [];
 let cnt = 0;
 if (tabLoad !== undefined)
 {
   table = tabLoad.map((row) => 
  {
    cnt++;
   
    return(
      [
        cnt,
        row.numeroCarteSim,  row.dateReceptionSim, row.numeroDeCommandeSim, row.numeroDeSerieSim, activeStr(row.active) , roundButtons(row.numeroDeSerieSim)
      ]
    )
     
  }
  )
  setTableData(table)
 }

}, [loadCardFromSagaUnique])



  
  
  return (
    <GridContainer>
      {alert}
      <GridItem xs={12}>
      <div className="d-flex justify-content-end">
          <Button className="mr-2" color="info" onClick={()=>{handleRefresh()}} disabled={false}>
             Refresh List
          </Button>
          <Button className="mr-2" color="info" onClick={()=>{ history(`${actualURL.url}/new`) }} disabled={false}>
             Ajouter Sim
          </Button>
          
        </div>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Sim</h4>
          </CardHeader>
          <CardBody>
            <Table
              tableHead={[
                "#",
                "tel",
                "Date Reception",
                "Numero Commande",
                "Numero de Serie",
                "active",
                "Action",
              ]}
              tableData={tableData}
              customCellClasses={[classes.center, classes.right, classes.right]}
              customClassesForCells={[0, 4, 5]}
              customHeadCellClasses={[
                classes.center,
                classes.right,
                classes.right
              ]}
              customHeadClassesForCells={[0, 4, 5]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}


const mapStateToProps = (state) => {
  return (
    {
      loadSimCardFromSaga : state.simCards
    }
  )
}

const mapsDispatchToProps = dispatch => {
  return (
    {
      simCardLoadDispatch: () => {dispatch(loadSimCardAction())},
      simCardDeleteDispatch: (data) => {dispatch(deleteSimCardAction(data))},      
  })
}

export default connect(mapStateToProps, mapsDispatchToProps)(CarteSim);