


// @material-ui/icons


import DashboardIcon from "@material-ui/icons/Dashboard";
import SimCardIcon from "@material-ui/icons/SimCard";
import ClientdIcon from "@material-ui/icons/Person";
import MapIcon from "@material-ui/icons/Map";
import NotificationIcon from "@material-ui/icons/Notifications";
import DeviceIcon from "@material-ui/icons/Router";
import PayementIcon from "@material-ui/icons/Euro";


import Client from "views/Client/Clients";
import User from "views/Users/User";
import Vehicule from "views/Vehicule/Vehicule";

import Device from "views/Device/Device";
import CarteSim from "views/CarteSim/CarteSim";
//import Parametre from "views/Parametre/Parametre";
import NotFound from "views/PageError/NotFound";
import SimCardUpdate from "views/CarteSim/SimCardUpdate";
import ClientUpdate from "views/Client/ClientUpdate";
import DeviceUpdate from "views/Device/DeviceUpdate";
import ClientDetail from "views/Client/ClientDetail";
import VehiculeUpdate from "views/Vehicule/VehiculeUpdate";
import UserUpdate from "views/Users/UserUpdate";
import Paiement from "views/Paiement/Paiement";
import LoginPage from "views/Auth/Login/LoginPage";
import PaiementClient from "views/Paiement/PaiementClient";
import Maps from "views/Maps/Maps";
import DashboardUser from "views/DashBoard/DashboardUser";
import PaiementClientUpdate from "views/Paiement/PaiementClientUpdate";
import ParametreUser from "views/Parametre/Parametre";
import NotificationUser from "views/InterfaceUser/Notification/NotificationUser";
import ExpirationPaiement from "views/ExpirationPayement/ExpirationPaiement";
import UserProfile from "views/Profile/UserProfile";
import React from "react";
/*
import Apps from "@material-ui/icons/Apps";

import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
*/
var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "dashboard",
    icon: DashboardIcon,
    component: <DashboardUser />,
    layout: "/user",
    hide: false
  },
  
  {
    path: "/gestionprofile",
    name: "Profile",
    rtlName: "Profile",
    icon: ClientdIcon,
    component: <UserProfile />,
    layout: "/user",
    hide: false
  }
  ,{
    path: "/gestionclient",
    name: "Gestion Clients",
    rtlName: "gestionclient",
    icon: ClientdIcon,
    component: <Client />,
    layout: "/admin",
    hide: false
  },
  {
    path: "/maps",
    name: "Maps",
    rtlName: "maps",
    icon: MapIcon,
    component: <Maps />,
    layout: "/user",
    hide: false
  },
  {
    path: "/notification",
    name: "Notification",
    rtlName: "notification",
    icon: NotificationIcon,
    component: <NotificationUser />,
    layout: "/user",
    hide: false
  },
  {
    path: "/gestionclient/new",
    name: "Ajout Client",
    rtlName: "Ajout Client",
    icon: ClientdIcon,
    component: <ClientUpdate />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/edit",
    name: "Edit Client",
    rtlName: "Edit Client",
    icon: ClientdIcon,
    component: <ClientUpdate />,
    layout: "/admin",
    hide: true
  },
  
  {
    path: "/gestionclient/:id/detail",
    name: "Detail Client",
    rtlName: "Detail Client",
    icon: ClientdIcon,
    component: <ClientDetail />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/user",
    name: "Detail Client User",
    rtlName: "Detail Client user",
    icon: ClientdIcon,
    component: <User />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/user/:userId/edit",
    name: "Modification Utilisateur",
    rtlName: "Modification Utilisateur",
    icon: ClientdIcon,
    component: <UserUpdate />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/user/new",
    name: "Nouvelle Utilisateur",
    rtlName: "Nouvelle Utilisateur",
    icon: ClientdIcon,
    component: <UserUpdate />,
    layout: "/admin",
    hide: true
  },

  {
    path: "/gestionclient/:id/detail/parametreuser",
    name: "Parametrage",
    rtlName: "Parametrage",
    icon: ClientdIcon,
    component: <ParametreUser />,
    layout: "/admin",
    hide: true
  },


  {
    path: "/gestionclient/:id/detail/vehicule",
    name: "Detail Client Vehicule",
    rtlName: "Detail Client Vehicule",
    icon: ClientdIcon,
    component: <Vehicule />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/vehicule/:vehiculeId/edit",
    name: "Edit Vehicule",
    rtlName: "Edit Vehicule",
    icon: ClientdIcon,
    component: <VehiculeUpdate />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/vehicule/new",
    name: "Nouveau Vehicule",
    rtlName: "Nouveau Vehicule",
    icon: ClientdIcon,
    component: <VehiculeUpdate />,
    layout: "/admin",
    hide: true
  },

  {
    path: "/gestionclient/:id/detail/paiement",
    name: "Detail Client Paiement",
    rtlName: "Detail Client Paiement",
    icon: ClientdIcon,
    component: <PaiementClient />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/paiement/:paiementId/edit",
    name: "Edit paiement",
    rtlName: "Edit paiement",
    icon: ClientdIcon,
    component: <PaiementClient />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionclient/:id/detail/paiement/new",
    name: "Nouveau paiement",
    rtlName: "Nouveau paiement",
    icon: ClientdIcon,
    component: <PaiementClientUpdate />,
    layout: "/admin",
    hide: true
  },


  {
    path: "/gestionpaiement",
    name: "Gestion Paiement",
    rtlName: "gestion paiement",
    icon: PayementIcon,
    component: <Paiement />,
    layout: "/admin",
    hide: false
  },

  {
    path: "/gestiondevice",
    name: "Gestion Device",
    rtlName: "gestion device",
    icon: DeviceIcon,
    component: <Device />,
    layout: "/admin",
    hide: false
  },
  {
    path: "/gestiondevice/new",
    name: "Ajout device",
    rtlName: "Ajout device",
    icon: DeviceIcon,
    component: <DeviceUpdate />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestiondevice/:id/edit",
    name: "Edit device",
    rtlName: "Edit device",
    icon: DeviceIcon,
    component: <DeviceUpdate />,
    layout: "/admin",
    hide: true
  },

  {
    path: "/gestionsim",
    name: "Gestion Cartes Sim",
    rtlName: "gestion Sim",
    icon: SimCardIcon,
    component: <CarteSim />,
    layout: "/admin",
    hide: false
  },
  {
    path: "/gestionsim/new",
    name: "Ajout Sim",
    rtlName: "Ajout Sim",
    icon: SimCardIcon,
    component: <SimCardUpdate />,
    layout: "/admin",
    hide: true
  },
  {
    path: "/gestionsim/:id/edit",
    name: "Edit Sim",
    rtlName: "Edit Sim",
    icon: SimCardIcon,
    component: <SimCardUpdate />,
    layout: "/admin",
    hide: true
  },

  

  {
    path: "/login-page",
    name: "Login Page",
    rtlName: "loginpage",
    mini: "L",
    rtlMini: "login",
    component: <LoginPage />,
    layout: "/auth",
    hide: true
  },
  {
    path: "/not-found",
    name: "not found",
    rtlName: "notFound",
    mini: "L",
    rtlMini: "notFound",
    component: <NotFound />,
    layout: "/auth",
    hide: true
  },
  {
    path: "/payement",
    name: "Paiement Validation",
    rtlName: "Paiement Validation",
    mini: "PV",
    rtlMini: "paiement",
    component: <ExpirationPaiement />,
    layout: "/validator",
    hide: true
  },
  
  
];
export default dashRoutes;
