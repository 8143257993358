import * as actionType from '../config/actionType'

const initialState = {
    playPauseReload: 0,
    speed: 1
};

// play 0   pause 1    prepreload  2     reload 3     stop   4

export const lecteurTrajetReducer = (state = initialState, action) => {
    
    switch (action.type)
    {
        case actionType.PLAY_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    playPauseReload: 0
                }
            )

            case actionType.PAUSE_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    playPauseReload: (state.playPauseReload !== 2) ? 1 : state.playPauseReload
                    //playPauseReload: 1
                }
            )
            case actionType.PREP_RELOAD_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    playPauseReload: 2
                }
            )
            case actionType.RELOAD_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    playPauseReload: 3
                }
            )
            case actionType.STOP_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    playPauseReload: 4
                }
            )
            case actionType.INC_SPEED_LECTEUR_DYN_TRAJET:
            return(
                {
                    ...state,
                    speed: (state.speed <= 5) ? (state.speed + 1) : 1
                }
            )
            case actionType.LOAD_DATE_DYN_TRAJET:
            return(
                state
            )

        default:
            return (state);
    }
}