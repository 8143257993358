import GridItem from "components/Grid/GridItem";
import React, { useEffect } from "react";
import GridContainer from "components/Grid/GridContainer";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import { FormControl, FormLabel } from "@material-ui/core";
import stylesForm from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Datetime from "react-datetime";
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilledRounded";
import PauseCircleIcon from "@material-ui/icons/Pause";
import ReplaIcon from "@material-ui/icons/Replay";
import { connect } from "react-redux";
import moment from 'moment';
import * as typeActionTrajet from '../../../../action/trajetAction'
import * as typeActionLecteur from '../../../../action/lecteurTrajetAction'

//import PlayCircleIcon from '@mui/icons-material/PlayCircle';


const useStylesButton = makeStyles(stylesButton);
const useStylesForm = makeStyles(stylesForm);

const TrajetAction = (props) => {
    const classesButton = useStylesButton();
    const classesForm = useStylesForm();
    const [dateTrajet, setDateTrajet] = React.useState(moment(new Date()).format('YYYY-MM-DD'));

    const handlePause = () => {
        console.log("pause")
        props.pauseLecteurDispatch();
    }

    const handleResume = () => {
        console.log("resume")
        sendCommandForAnimTrajet();
    }

    const handlePlay = () => 
    {
        console.log("play")
        props.playLecteurDispatch();
    }


    
    const handleDateRecep = (data) => {

        const date = moment(data).format('YYYY-MM-DD');
        if (date.length === 10)
        {
          setDateTrajet(date)
        }
        
        
      }

      
      const sendCommandForAnimTrajet = () => {
        if ((dateTrajet.length > 0) && (props.loadVehiculSelectedRedux.numeroImmatriculation !== ""))
                {
                        props.loadTrajetDispatch({
                                matricule: props.loadVehiculSelectedRedux.numeroImmatriculation,
                                date: dateTrajet
                        })
                }
      }

      useEffect(() => 
        {
                sendCommandForAnimTrajet();  

        }, [dateTrajet, props.loadVehiculSelectedRedux.numeroImmatriculation])


    return (
        <Card>
                <CardBody>
                        <GridContainer>
                                <GridItem md={2}>
                                        <Card>
                                                <CardBody>
                                                        
                                                        <FormControl >
                                                                <Datetime
                                                                
                                                                        onChange={handleDateRecep}
                                                                        initialValue={moment(dateTrajet).format('DD-MM-YYYY')}
                                                                        //value={dateTrajet}
                                                                        initialViewDate={new Date()}
                                                                        dateFormat={'DD-MM-YYYY'}
                                                                        timeFormat={false}
                                                                        closeOnSelect={true}
                                                                        inputProps={
                                                                        { placeholder: "Date trajet",
                                                                        
                                                                        }}
                                                                />
                                                        </FormControl>
                                                </CardBody>
                                        </Card>
                                </GridItem>
                                <GridItem >
                                        <Card>
                                          <CardBody>
                                                <Button size={"sm"} color="facebook" round   onClick={() => {handlePlay()}}>
                                                        <PlayCircleIcon />
                                                </Button>
                                                <Button size={"sm"}  style={{backgroundColor: '#12824C', color: '#FFFFFF'}} round  onClick={() => {handlePause()}} >
                                                        <PauseCircleIcon />
                                                </Button>
                                                <Button size={"sm"} color="facebook" round onClick={() => {handleResume()}}>
                                                        <ReplaIcon />
                                                </Button>
                                                </CardBody>
                                        </Card>
                                </GridItem>
                                <GridItem md="auto">
                                        <Card>
                                                <CardBody>
                                                        <GridContainer direction="column" >
                                                                <GridItem>
                                                                        <GridContainer>
                                                                                <GridItem>
                                                                                <span style={{fontWeight: 'bold'}}> Date : </span> {props.loadDateDynProps.dateDyn}
                                                                                </GridItem>
                                                                                <GridItem>
                                                                                <span style={{fontWeight: 'bold'}}> Vitesse : </span>{Math.floor(props.loadDateDynProps.vitesse)} Km/h
                                                                                </GridItem>
                                                                               
                                                                        </GridContainer>
                                                                </GridItem>
                                                                
                                                        </GridContainer>
                                                </CardBody>  
                                        </Card>
                                </GridItem>
                        </GridContainer>
                 </CardBody>
        </Card>
        
    );
}


const mapsDispatchToProps = (dispatch) => {
  
        return (
          {
            
            loadTrajetDispatch: (data) => dispatch(typeActionTrajet.loadTrajetAction(data)),
            playLecteurDispatch : () => dispatch(typeActionLecteur.playTrajetAction()),
            pauseLecteurDispatch : () => dispatch(typeActionLecteur.pauseTrajetAction()),
            stopLecteurDispatch : () => dispatch(typeActionLecteur.stopTrajetAction()),
      
          })
      }
      
      
      const mapsStateToProps = (state) =>
      {
        return(
          {
            loadVehiculSelectedRedux: state.vehicules.vehiculeSelected,
            loadDateDynProps: state.dateDyn
            //getVehiculeStateToProps: state.vehiculeItem
          }
        )
        
      }

      export default connect(mapsStateToProps, mapsDispatchToProps)(TrajetAction);