import React, { useEffect } from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem";
import { Label } from "@material-ui/icons";
import FormLabel from "@material-ui/core/FormLabel";
import GridContainer from "components/Grid/GridContainer.js";
import Datetime from "react-datetime";
import moment from 'moment';
import Button from "components/CustomButtons/Button.js";
import FormControl from "@material-ui/core/FormControl";
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { connect } from "react-redux";
import * as typeActionTrajet from '../../../../action/trajetAction'
import * as typeActionLecteur from '../../../../action/lecteurTrajetAction'


const useStyles = makeStyles(styles);

const TrajetSubmit = (props) => {

  const [dateTrajet, setDateTrajet] = React.useState("");
  const classes = useStyles();

  const handleDateRecep = (data) => {

    const date = moment(data).format('YYYY-MM-DD');
    if (date.length === 10)
    {
      setDateTrajet(date)
    }
    
    
  }

  useEffect(() => 
  {
    if ((dateTrajet.length > 0) && (props.loadVehiculSelectedRedux.numeroImmatriculation !== ""))
    {
      props.loadTrajetDispatch({
        matricule: props.loadVehiculSelectedRedux.numeroImmatriculation,
        date: dateTrajet
      })
    }

  }, [dateTrajet, props.loadVehiculSelectedRedux.numeroImmatriculation])

  const handleButTrajetValider = () => {
   // console.log(dateTrajet)
  }


    return (
        <GridItem xs={11} sm={3}>
            <Card>
                <CardBody style={ style.cardBody}>
                <GridContainer>
                      
                      <GridItem xs={2} sm={2}>
                         <FormLabel className={classes.labelHorizontal}>
                           Date 
                         </FormLabel>
                       </GridItem>
                       <GridItem xs={2} sm={7}>
                       <br />
                       
                         <FormControl >
                           <Datetime
                           
                                 onChange={handleDateRecep}

                                 dateFormat={'DD-MM-YYYY'}
                                 timeFormat={false}
                                   closeOnSelect={true}
                                   inputProps={
                                     { placeholder: "Date trajet",
                                   
                                 }}
                                 />
                           </FormControl>
                       </GridItem>
                       <GridItem xs={2} sm={2}>
                        
                         <Button color="info" onClick={handleButTrajetValider}>
                            OK
                        </Button>
                       </GridItem>
                       
                </GridContainer>
                        
                
                
                </CardBody>
            </Card>
         </GridItem>
    )
}


const style = {
    imageVehicule: {
        position: "relative",
        width: "100%",
        height: "auto",
        verticalAlign: "top",
        background: "hsl(0, 0%, 98%)"
      },
      cardBody: {
        //height: 230
        //color: "blue"
      },
      button: {
        height: 600,
        verticalAlign: "center"
        //color: "blue"
      }
}

const mapsDispatchToProps = (dispatch) => {
  
  return (
    {
      
      loadTrajetDispatch: (data) => dispatch(typeActionTrajet.loadTrajetAction(data)),
      playLecteurDispatch : () => dispatch(typeActionLecteur.playTrajetAction()),
      pauseLecteurDispatch : () => dispatch(typeActionLecteur.pauseTrajetAction()),
      stopLecteurDispatch : () => dispatch(typeActionLecteur.stopTrajetAction()),

    })
}


const mapsStateToProps = (state) =>
{
  return(
    {
      loadVehiculSelectedRedux: state.vehicules.vehiculeSelected
      //getVehiculeStateToProps: state.vehiculeItem
    }
  )
  
}


export default connect(mapsStateToProps, mapsDispatchToProps)(TrajetSubmit)