import * as types from '../config/actionType'

export  const loadTrajetAction = (data) => {
    
    
    return {
        type: types.LOAD_TRAJET,
        data: data
    }
    
}

export  const trajetActionLoaded = (position) => 
{
   
    return {
        type: types.TRAJET_LOADED,
        position: position
    }
}
