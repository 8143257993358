
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import Button from "components/CustomButtons/Button.js";
import * as typeAction from '../../../action/trajetAction'
import {
    Polyline
  } from "@react-google-maps/api";


const TrajetPolyline = (props) => {

    const [positionTrajet, setPositionTrajet] = useState([]);
    const [conteur, setConteur] = useState(0)


   
    useEffect(() => {
       
        if ((props.loadTrajetFromSaga.positions !== undefined) && (props.loadTrajetFromSaga.positions !== null))
        {
            const tabPosFilter = props.loadTrajetFromSaga.positions.map((tab) => {
                const latLong = {
                    ...tab,
                    lat: Number(tab.latitude),
                    lng: Number(tab.longitude)
                }
                return (latLong)
            })
            setPositionTrajet(tabPosFilter)
        }
        
        //setPositionTrajet(tabPosFilter)

    }, [props.loadTrajetFromSaga])


    
   

return (
 <Polyline 
    
    path={positionTrajet}
    strokeColor={ "#FF0000"}
    strokeOpacity={ 1.0}
    strokeWeight={ 1.5}
 />   
)

}




const mapsStateToProps = (state) => {
  
    return (
      {
        loadTrajetFromSaga: state.trajet,
      //  loadLecteurTrajetInfo: state.lecteurTrajet,
        //loadDateDyn: React.memo(()=> {state.dateDyn.compteur}, [])
      }
    )
  }

  const mapsDispatchToProps = (dispatch) => {
  
    return (
      {
        
        loadTrajetDispatch: (data) => dispatch(typeAction.loadTrajetAction(data)),
        //playLecteurDispatch : () => dispatch(typeActionLecteur.playTrajetAction()),
        //pauseLecteurDispatch : () => dispatch(typeActionLecteur.pauseTrajetAction()),
        //stopLecteurDispatch : () => dispatch(typeActionLecteur.stopTrajetAction()),
  
      })
  }
  
  export default connect(mapsStateToProps, mapsDispatchToProps)(TrajetPolyline);