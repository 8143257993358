
  import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
  import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { connect } from "react-redux";

import * as typeAction from '../../../action/mapsAction'
import { useEffect } from "react";
import { useState } from "react";
import MarkerVehicule from "./MarkerVehicule";
import { useRef } from "react";
import React, { useCallback } from "react";
import TrajetPolyline from "../Trajet/TrajetPolyline";
import AnimatedMarkerTrajet from './AnimatedMarkerTrajet';
import useWindowDimensions from 'components/SentrackingComponent/useWindowDimensions';

import * as typeActionMarker from "../../../action/mapsAction" 
import AnimatedMarkersCustum from './AnimatedMarkersCustum';
import TrajetArret from '../Trajet/TrajetArret';
import { constanceMode } from 'constance/constance';
import Button from "components/CustomButtons/Button.js";
import ReactDOM from 'react-dom'
import PlayCircleIcon from "@material-ui/icons/PlayCircleFilledRounded";



const center = {
   lat: 14.68307, lng: -17.44723666 
};

const GoogleMapElement = (props) => 
{

  const { height, width } = useWindowDimensions();
  
  const widthPerCent = (width * 55) /100 
  const containerStyle = {
    //width: widthPerCent,
    height: '750px'
  };

 
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    //googleMapsApiKey: "AIzaSyAzJ-PiU66ynMyqUOCfTXW2aIuAKNeuRvY"
    //googleMapsApiKey: "AIzaSyB1wWyhSSZU9oylFyqO6XPM8WudMzjOQtQ"
    //googleMapsApiKey: "AIzaSyCSrAhAlvOtCUDP5SJYauzRwCA0_1TtkUU"
    googleMapsApiKey: "AIzaSyCR_-NEgD4YHz57j0K7vxvSTfHBp6-pqhE"
  })

  const [map, setMap] = React.useState(null)
  const [positionTrajet, setPositionTrajet] = useState([]);
  const [mapClicked, setMapClicked] = useState(0)

  const handleButSateliteView = () => {

  }

  useEffect(() => 
{
  if (props.loadTrajetFromSaga.positions)
  {
    if (props.loadTrajetFromSaga.positions.length > 0)
    {
      
      setPositionTrajet(props.loadTrajetFromSaga.positions)
      //props.playLecteurDispatch();
      //console.log(props.loadTrajetFromSaga.positions)
      
    }
    else
    {
      setPositionTrajet([])
      //setLoading(false)
    }
  }
}, [props.loadTrajetFromSaga.positions])



  const onLoad = React.useCallback(function callback(map) 
  {
    //const bounds = new window.google.maps.LatLngBounds({lat: 14.68307, lng: -17.44723666});

    //map.fitBounds(bounds);
    console.log("LOAD MAP")
    
    setMap(map)
/*
    const google = window.google;
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(
      <div style={{height: "30"}}>
        <div>
          <Button size={"sm"} color="facebook" round  onClick ={() => {handleButSateliteView()}} >
              <PlayCircleIcon /> Satelite
          </Button>
        </div>
        
    </div>
    , controlButtonDiv);
    map.controls[google.maps.ControlPosition.TOP_LEFT ].push(controlButtonDiv);
  
*/
  }, [])

  const onUnmount = React.useCallback(function callback(map) 
  {
    console.log("UNLOAD MAP")
    setMap(null)
  }, [])

  
    const refMap = useRef(null);


    useEffect(() => {

      

      if (props.mapActionRedux.etatCommand === 1)
      {
        if (props.loadVehiculSelectedRedux.latitude !== "")
       {
          const latitude = Number(props.loadVehiculSelectedRedux.latitude)
          const longitude = Number(props.loadVehiculSelectedRedux.longitude)

            if (map)
            {
              map.panTo(
                new window.google.maps.LatLng(latitude, longitude)
              );
            }
          }
      }
      props.centerEndMarkerDispatch();
      

    }, [props.mapActionRedux.etatCommand])


   // console.log("props.mapActionRedux.etatCommande ", props.mapActionRedux.etatCommand)

    useEffect(() => {
       // console.log('load redux',props.loadVehiculSelectedRedux)

       if (props.loadVehiculSelectedRedux.latitude !== "")
       {
          const latitude = Number(props.loadVehiculSelectedRedux.latitude)
          const longitude = Number(props.loadVehiculSelectedRedux.longitude)

          if (map)
          {
            map.panTo(
              new window.google.maps.LatLng(latitude, longitude)
            );
          }
          
          
          }
    }, [props.loadVehiculSelectedRedux])


     
    return isLoaded ? (
      <GoogleMap
      
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onClick={() => {setMapClicked(mapClicked + 1)}}
        onUnmount={onUnmount}
        mapTypeId={"terrain"}
        clickableIcons= {false}
        options={{
          scrollwheel: false,
          clickableIcons: false,
          streetViewControl: false,
          mapTypeControl: true
        }}
        
      >
        {props.loadModeSelected !== constanceMode.TRAJET ? <MarkerVehicule map={map}  mapClicked={mapClicked} /> : <> </>}
        
        {props.loadModeSelected === constanceMode.TRAJET ? <TrajetPolyline /> : <> </>}
        
       { /* <AnimatedMarkerTrajet /> */ }

       {props.loadModeSelected === constanceMode.TRAJET ? (
         <TrajetArret mapClicked={mapClicked} />
       ) : <> </>}

       
      {props.loadModeSelected === constanceMode.TRAJET ? (
        <AnimatedMarkersCustum 
       
        photoVehicule={props.loadVehiculSelectedRedux.photoVehicule}//loadVehiculSelectedRedux
         typeVehicule={props.loadVehiculSelectedRedux.typeVehicule}
         coordinates={positionTrajet}
         interval={200}
    
    
     />
      ) : <> </>}
       



        { /* Child components, such as markers, info windows, etc. */ }
        <></>
      </GoogleMap>
  ) : <></>

  /*
    return (
    <GoogleMap
            ref={refMap}
            defaultZoom={14}
            mapTypeId={"terrain"} //14.729585220085383, -17.490960058213094
            defaultCenter={{ lat: 14.68307, lng: -17.44723666 }}
            defaultOptions={{
              scrollwheel: false,
              clickableIcons: false,
              streetViewControl: false
            }}
            defaultClickableIcons={false}
            clickableIcons={false}
          >
            <MarkerVehicule />
            <TrajetPolyline />

          </GoogleMap>
    )
    */
}

const mapsDispatchToProps = (dispatch) => {
        return (
                {
                  
                  centerEndMarkerDispatch: () => dispatch(typeActionMarker.centerToMarkersEndAction()),
                  
            
                })
}

const mapStateToProps = (state) => {

  //console.log("state.vehicules.vehiculeSelected (google map)", state.vehicules.vehiculeSelected)
  
    return ({
      loadTrajetFromSaga: state.trajet,
        loadVehiculSelectedRedux: state.vehicules.vehiculeSelected,
        mapActionRedux: state.mapAction,
        loadModeSelected: state.modeCommand.mode
    }
    )
  
  }
  

export default connect(mapStateToProps, mapsDispatchToProps)(React.memo(GoogleMapElement))