import {takeEvery, call, put, all, takeLatest} from  'redux-saga/effects'
import * as actionTypeConf from '../config/actionType'
import * as actions from '../action/trajetAction'
import * as trajetServices from '../services/trajetService'


function* watchLoadTrajet()
{
   
    yield takeLatest(actionTypeConf.LOAD_TRAJET, loadTrajet);
     
}

function* loadTrajet(dataTrajet)
{
    try {
        
        const positions = yield call(trajetServices.getListPosition, dataTrajet)
        yield put(actions.trajetActionLoaded(positions))
    } catch (e) {
       // console.log(e)

    } 
}

export function* trajetSaga() {
    yield all([
        watchLoadTrajet()
    ])
}