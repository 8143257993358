
import GridContainer from "components/Grid/GridContainer";
import React from "react";
import InfoVehicule from "./InfoVehicule";
import ListVehicule from "./ListVehicule";
import MapComponent from "./mapComponent/MapComponent";

import GridItem from "components/Grid/GridItem.js";
import Pagination from "components/Pagination/Pagination.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles } from "@material-ui/core/styles";

import stylesButton from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";
import stylesGrid from "assets/jss/material-dashboard-pro-react/views/gridSystemStyle.js";
import Commande from "views/CommandeVehicule/Commande";
import TrajetInfo from "./Trajet/TrajetInfo/TrajetInfo";
import ListVehiculeTab from "./ListVehiculeTab";
import InfoVehiculeVert from "./InfoVehiculeVert";
import CommandAction from "./Command/CommandAction";
import TrajetAction from "./Trajet/TrajetAction/TrajetAction";
import MapAction from "./mapComponent/MapAction/mapAction";
import TabTrajetArret from "./Trajet/tabTrajetArret";
import { connect } from "react-redux";
import { constanceMode } from "constance/constance";
import ParametreAction from "./Parametre/ParametreAction"


const useStylesButton = makeStyles(stylesButton);
const useStylesGrid = makeStyles(stylesGrid);

const Maps = (props) => {

    const classesButton = useStylesButton();
    const classesGrid = useStylesGrid();

  
  

    return (
        <>
        <ListVehiculeTab />

        
            {
               // <CommandAction />
                
               // 
             //   <InfoVehicule />
             //<TrajetInfo />
             
            }
            { props.loadModeSelected === constanceMode.MAP ? <MapAction /> : <> </>}
            { props.loadModeSelected === constanceMode.TRAJET ? <TrajetAction /> : <> </>}
            { props.loadModeSelected === constanceMode.COMMAND ? <CommandAction /> : <> </>}   
            { props.loadModeSelected === constanceMode.PARAMETRE ? <ParametreAction /> : <> </>}               
               
            
            
            
            <GridContainer>
                <GridItem xs={3}>
                    <InfoVehiculeVert/>
                </GridItem>
                <GridItem xs={9}>
                    <MapComponent />
                </GridItem>
            </GridContainer>
            {(props.loadModeSelected === constanceMode.TRAJET) ? <TabTrajetArret /> : <> </>} 
            
        </>
    )

}


const mapStateToProps = (state) => {

    //console.log("state.vehicules.vehiculeSelected (google map)", state.vehicules.vehiculeSelected)
    
      return ({
        
          loadModeSelected: state.modeCommand.mode
      }
      )
    
    }
    
  
  export default connect(mapStateToProps)(Maps);